import axios from "axios";
import { ElMessage } from "element-plus";
import { showToast } from "vant";
import { myLocalStorage } from "@/utils/index";
import i18n from "@/i18n/index.js";
const tips = i18n.global.t("alert_tip.timer");
let u = window.navigator.userAgent;
var isMb = u.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

const code = [50000, 50001, 50002, 50003, 50004, 50005, 50009, 40001, 40002, 40003, 40004, 40006, 40007];

// console.log('x', process.env.NODE_ENV, process.env);
// const apiUrl = 'http://your.api.url'
// 创建axios实例
const request = axios.create({
	baseURL: process.env.NODE_ENV === "production" ? "/sleep" : "/sleep",
	timeout: 15000, // 5s超时
});

// 请求拦截器 一般写法模式
request.interceptors.request.use(
	(response) => {
		response.headers["lang"] = localStorage.getItem("lang") == "en" ? "en_US" : "zh_CN";
		const accessToken = myLocalStorage.get("access-token", "access-token");
		if (accessToken) {
			response.headers["Access-Token"] = accessToken;
		}
		return response; // 请求成功则返回response
	},
	(error) => {
		// 请求失败则显示错误状态
		//message.error(error.message)
		return Promise.reject(error);
	}
);

// 响应拦截器
request.interceptors.response.use(
	(response) => {
		if (code.includes(response.data.status)) {
			if (isMb) {
				showToast(response.data.msg);
			} else {
				ElMessage.error(response.data.msg);
			}
		}

		if (response.headers["access-token"]) {
			myLocalStorage.set("access-token", "access-token", response.headers["access-token"]);
		}

		return response.data;
	},
	(error) => {
		let config = error.config;

		if (!config || !config.retry) {
			if (config.url && config.url.includes("task/listDetail")) return false;

			if (isMb) {
				showToast(tips);
			} else {
				ElMessage.error(tips);
			}
			return false;
		}
		//message.error(error.message)
		return Promise.reject(error);
	}
);

// 导出request
export default request;
