import i18n from "@/i18n/index.js"
import { ElMessage } from 'element-plus'
import { webMsgList } from './enum'

/** 
 * 防抖
 * fn 执行函数
 * interval 间隔时间
 * */ 
export function _debounce(fn, delay) {
  console.log(fn);
    var delay = delay || 200;
    var timer;
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, delay);
    };
}
/** 
 * 节流
 * fn 执行函数
 * interval 间隔时间
 * */ 
export function _throttle(fn, interval) {
    var last;
    var timer;
    var interval = interval || 200;
    return function () {
        var th = this;
        var args = arguments;
        var now = +new Date();
        if (last && now - last < interval) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(th, args);
            }, interval);
        } else {
            last = now;
            fn.apply(th, args);
        }
    }
}

/** 
 * 校验是否正确的邮箱格式
 * */ 
export const isEmailAvailable = (obj) => {
  let email = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  if (!email.test(obj)) {
    return false;
  }
  return true;
}


/** 
 * 前端缓存
 * */ 
export const myLocalStorage = {
    set(namespace = 'visitPlanList', key, data, time) {
      const storage = JSON.parse(localStorage.getItem(namespace)) || {}
      storage[key] = {
        data,
        createTime: Date.now(),
        lifeTime: time || 1000 * 60 * 60 * 24 * 7 // 默认设置过期时间：7天
      }
      localStorage.setItem(namespace, JSON.stringify(storage))
    },
    get(namespace = 'visitPlanList', key) {
      const storage = JSON.parse(localStorage.getItem(namespace)) || {}
      if (!storage[key]) return
      const { data, createTime, lifeTime } = storage[key]
      // 当前时间 - 存入时间 > 过期时间
      if (Date.now() - createTime > lifeTime) {
        delete storage[key]
        localStorage.setItem(namespace, JSON.stringify(storage))
        return null
      } else {
        return data
      }
    },
    delete(namespace = 'visitPlanList', key) {
      const storage = JSON.parse(localStorage.getItem(namespace)) || {}
      if (!storage[key]) return
      delete storage[key]
      localStorage.setItem(namespace, JSON.stringify(storage))
    },

    deleteAll(namespace = 'visitPlanList') {
      localStorage.setItem(namespace, "{}")
    }
  }
  

/**
 * pc端使用canvas下载图片
 */
export const downloadPicture = (imgSrc, name) => {
  const image = new Image()
  // 解决跨域Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.src = imgSrc
  image.onload = () => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = name || 'photo'
      a.href = url
      a.click()
      a.remove()
      // 用完释放URL对象
      URL.revokeObjectURL(url)
    })
  }
}

/**
 * 
 * pc ElMessage 方法
 */
const  list = []
export const sleepMessage = (text) =>{
    list.push(ElMessage(text))
    if (list.length > 1) {
      list[0].close()
      list.shift()
    }
}


/**
 * 
 *web3 连接钱包错误提示
 */

 export const wb3ErrorMessage = (err) => {
  const {code, message} = err
  const value =  webMsgList.find(item => code === item.code)
  if (value  && i18n.global.locale.value === 'zh') {
      // 中文提示
      sleepMessage(value.msg)
  }else {
    // 英文提示
    sleepMessage(message)
  }
 }