import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus' // element 组件
import 'element-plus/dist/index.css' // element 组件
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // element图标
import vantComponent from '@/utils/vant' // vant组件
import 'vant/lib/index.css'; // vant组件
import '@/assets/scss/index.scss' // 样式
import '@/style/index.scss' // 样式
import 'swiper/css'; // 轮播图样式
import 'animate.css'; // 动画
import 'amfe-flexible'; // css单位自适应插件
import router from "./router/index" // 路由
import i18n from './i18n'; // g国际化
import { createPinia } from 'pinia' // 状态管理
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate' // 持久化插件

// import mitt from 'mitt';

// import Vconsole from 'vconsole' // 移动端调试
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate) // 持久化
const app = createApp(App)
// 注册图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 注册vant组件
vantComponent.forEach(item =>{
  
  app.use(item)
})
app.use(i18n)
app.use(router)
app.use(ElementPlus)
app.use(pinia)
// app.use(new Vconsole())
app.mount('#app')
// app.config.globalProperties.mittBus = mitt();