<script setup>
import LoginDialog from "./components/Login";
import GDPR from "./components/GDPR";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
const { locale, t } = useI18n();

const loginVisivle = ref(false);
const code = ref("undefined"); // 邀请码

const isZh = computed(() => {
	return locale.value === "zh";
});

window.closeMLogin = () => {
	loginVisivle.value = false;
};

window.mselfLogin = (invcode) => {
	code.value = invcode;
	loginVisivle.value = true;
};

const showLetter = ref(false);
const dear = ref("");
const tell = ref("");
window.showLetter = (value) => {
	showLetter.value = true;
	dear.value = value;
	tell.value = t("card.card3_letter3").replace('$', 'support@dering.io');
    document.body.style.height = '100vh'
    document.body.style.overflow = 'hidden'
};
window.closeLetter = () => {
	showLetter.value = false;
    document.body.height = 'auto'
    document.body.style.overflow = 'auto'
};

const closeLetter = () => {
    window.closeLetter();
}
</script>

<template>
	<div>
		<div class="layout">
			<!-- login弹框 -->
			<LoginDialog :visible="loginVisivle" :code="code" @closeDialog="closeDialog"></LoginDialog>

			<div class="Reserved_dialog" v-show="showLetter" :class="{ en: !isZh }">
				<div class="letter_box">
					<img src="@/assets/images/pages/logo/letterTop.svg" alt="" />
					<img src="@/assets/images/pages/logo/letterBottom.svg" alt="" />
					<img src="@/assets/images/pages/logo/DeRing4.png" alt="" />

					<div class="dialog_title">
						<p class="title">
							{{ t("card.card3_letter1") }}
						</p>
						<p :key="isZh" class="dear">
							{{ dear }}:
						</p>
						<p class="content">
							{{ tell }}
						</p>
					</div>

					<div class="dialog_button" @click="closeLetter">
						{{ t("confirmText.outConfirm.yes") }}
					</div>
				</div>
			</div>

			<router-view class="layout_view"></router-view>
			<GDPR />
		</div>
	</div>
</template>

<style scoped lang="scss">
.Reserved_dialog {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: rgba($color: #000000, $alpha: 0.6);
    // pointer-events: none;
	&.en {
		.dialog_button {
			letter-spacing: 0 !important;
			padding-left: 0 !important;
		}
	}

	.letter_box {
		height: 380px;
		width: 375px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		img {
			width: 100%;
			position: absolute;

			width: 100%;
			left: 50%;
			transform: translateX(-50.5%);

			& + img {
				bottom: 0;
				transform: none;
				left: 0;
				& + img {
					width: 20%;
					right: 17%;
					top: 55%;
					left: auto;
					transform: none;
				}
			}
		}

		.dialog_title {
			color: white;
			position: absolute;
			.title {
				text-align: center;
				font-size: 30px;
				margin-top: 40px;
				margin-bottom: 0;
			}

			.dear {
				font-size: 16px;
				margin: 0;
				margin-left: 10%;
				margin-top: 15px;
			}
			.content {
				font-size: 12px;
				width: 80%;
				margin: 15px auto;
			}
		}

		.dialog_button {
			position: absolute;
			width: 40%;
			height: 40px;
			font-size: 24px;
			line-height: 40px;
			background-color: white;
			color: black;
			border-radius: 30px;
			text-align: center;
			letter-spacing: 10px;
			left: 50%;
			bottom: 20%;
			transform: translateX(-50%);
			padding-left: 10px;
		}
	}
}
</style>
